<template>
  <div>
    <h2 class="my-3">Import Master Data</h2>
    <div class="file-wrapper"
      @dragover.prevent
      @drop.prevent>
      <div @drop="handleFileDrop">
        <input type="file"
          name="primary-file-input"
          @change="onChangeFile" />
      </div>
      <div class="d-flex">
        <v-icon class="align-self-center"
          size="50px">mdi-file-table-outline</v-icon>
        <p class="align-self-center">Drag primary file here or click to upload.</p>
        <ul 
          class="mt-6 ml-6"
          v-show="loadingPrimaryFile === false">
          <li v-for="(file, index) in primaryFile"
            :key="index"
            class="fileList">
            {{ file.name }} ({{ file.size / 1000 }} Kb.) 
            <button @click="removeFilePrimary(index)"
              title="Remove">X</button>
          </li>
        </ul>
        <v-spacer />
      </div>
      <v-progress-linear
        :active="loadingPrimaryFile.loading"
        :indeterminate="loadingPrimaryFile.loading"
        height="25"
        color="cyan">
        <strong>{{ Math.ceil(loadingPrimaryFile.uploadPercentage) }}%</strong>
      </v-progress-linear>
    </div>
  </div>
</template>

<script>
export default {
  name: 'importAudienceMasterData',
  props: ['loadingPrimaryFile'],
  data() {
    return {
      loadingValuePrimaryFile: '',
      primaryFile: [],
      primaryFileData: [],
      primaryHeader: [],
      sortOrders: {},
      progress: ''
    }
  },

  watch: {

  },

  methods: {
    onChangeFile(event) {
      const input = event.target;
      if (!event.target.files) return;

      const fd = new FormData();

      ([...input.files]).forEach((f) => {
        this.primaryFile.push(f);
        fd.append('file', f)
      });

      this.$store.commit('audiences/UPLOADSTATUS', true);
      this.$store.dispatch('audiences/AddAudienceMaster', fd)
    },

    handleFileDrop(e) {
      const droppedFiles = e.dataTransfer.files;

      // eslint-disable-next-line no-useless-return
      if (!droppedFiles) return;

      ([...droppedFiles]).forEach(f => {
        this.primaryFile.push(f);
      })
    },

    removeFilePrimary(fileKey){
      this.primaryFile.splice(fileKey, 1);
      this.primaryFileData = [];
      this.primaryHeader = []
    },

    remoeMasterData() { 
      this.$store.dispatch('jobManagement/DELETE_MASTER_DATA');
    }
  }
}
</script>

<style lang="scss" scoped>
  .v-progress-circular {
    margin: 0.5rem;
  }

  .file-wrapper {
    text-align: center;
    margin: auto auto;
    border-style: dashed;
    border-width: 1px;
    border-radius: 5px;
    min-width: 350px;
    height: 60px;
    vertical-align: middle;
    display: block;
    position: relative;
    overflow: hidden; 
      input {
        position: absolute;
        top: 0;
        right: 0;
        cursor: pointer;
        opacity: 0.0;
        filter: alpha(opacity=0);
        font-size: 300px;
        height: 100px;
      }

      ul {
        position: absolute;
      }
  }

  .fileList {
    color: #039BE5;
    list-style-type: none;
  }

  .hr-sect {
    display: flex;
    flex-basis: 100%;
    align-items: center;
    color: rgba(0, 0, 0, 0.35);
    margin: 8px 0;

    &:before, &:after {
        content: "";
        flex-grow: 1;
        background: rgba(0, 0, 0, 0.35);
        height: 1px;
        font-size: 0;
        line-height: 0;
        margin: 0 8px;
    }
}
</style>